<template>
  <div id="MyPage" class="wrap">
    <div class="formWrap contentsWidth mx-auto">
      <h1 class="title" v-if="isEdit">マイページ - ユーザー情報の編集</h1>
      <h1 class="title" v-else>マイページ - ユーザー情報</h1>
      <div class="inputBox">
        <v-form-name
          v-if="isEdit"
          label="氏名"
          :left.sync="sei"
          :right.sync="mei"
          :placeholders="{left: '例）山田' , right: '例）太郎'}"
          :errors="{left: errors.sei || '', right: errors.mei || ''}"
          :isRequired="true"
        >
        </v-form-name>
        <v-show-data
         v-else
         label="氏名"
         :data="`${sei} ${mei}`"
        />
        <hr>
        <v-form-name
          v-if="isEdit"
          label="ふりがな"
          :left.sync="kana_sei"
          :right.sync="kana_mei"
          :placeholders="{left: '例）やまだ' , right: '例）たろう'}"
          :errors="{left: errors.kana_sei || '', right: errors.kana_mei || ''}"
          :isRequired="true"
        >
        </v-form-name>
        <v-show-data
         v-else
         label="ふりがな"
         :data="`${kana_sei} ${kana_mei}`"
        />
        <hr>
        <v-form-birth
          v-if="isEdit"
          label="生年月日"
          name="birth"
          :placeholders="{year: '例）1980', month: '例）10', day: '例）10'}"
          :errMessage="errors.birth || []"
          :isRequired="true"
          v-model="birth">
        </v-form-birth>
        <v-show-data
         v-else
         label="生年月日"
         :data="birth"
        />
        <hr>

        <v-form-text
          v-if="isEdit"
          label="機関名（大学・大学院等）"
          itemStyle="line"
          inputClass="w-long"
          maxLength="500"
          placeholder="例）国際大学"
          :isRequired="true"
          :errMessage="errors.institution || []"
          v-model="institution"
        />
        <v-show-data
         v-else
         label="機関名（大学・大学院等）"
         :data="institution"
        />
        <hr>

      </div>

      <div v-if="isEdit" class="btnWrap">
        <b-button
          class="btn cancel bold"
          @click="inputReset"
        >
          キャンセル
        </b-button>
        <b-button
          class="btn save bold"
          @click="save"
          :disabled="!isChange"
        >
          更新
        </b-button>
      </div>
      <div v-else class="btnWrap">
        <b-button
          class="btn back bold"
          @click="$router.push({ name: 'gasTop' })"
        >
          トップに戻る
        </b-button>
        <b-button
          class="btn save bold"
          @click="edit"
        >
          編集する
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import CONST_USER_INFO from '@/constants/userInfo';

export default {
  name: 'GasMyPage',
  data() {
    return {
      isEdit: false,
      isInit: [true, true],
    };
  },
  methods: {
    edit() {
      window.scroll(0, 0);
      this.isEdit = true;
    },
    async inputReset() {
      const CONF_MSG = '入力内容は破棄されます。このページから移動してもよろしいですか？';
      if (!this.isChange || await this.confirm(CONF_MSG)) {
        this.$store.commit('userInfo/resetInputData');
        this.$store.commit('userInfo/changeCheck');
        this.$store.commit('userInfo/resetErrorMsg');
        this.isEdit = false;
      }
    },
    async save() {
      console.log(this.$store.state.userInfo.inputData);
      if (!await this.confirm('現在の内容で保存してよろしいですか？')) {
        return;
      }
      window.scroll(0, 0);
      this.$store.dispatch('page/onWaiting');
      this.$store.commit('userInfo/resetErrorMsg');
      const params = this.$store.state.userInfo.inputData;
      const res = await api.send('/api/user/myInfo/save', params)
        .catch(async (error) => {
          this.$store.commit('userInfo/setErrorMsg', error.response.data.message);
          this.$store.dispatch('page/offWaiting');
          if (error.response.data.isAlert) {
            await this.alert(this.errors.errorMessage);
          }
          console.log(error);
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (res !== false) {
        this.$store.commit('userInfo/setSavedData');
        this.$store.commit('userInfo/changeCheck');
        this.isEdit = false;
        await this.alert('保存が完了しました。');
      }
    },
  },
  computed: {
    sex_option() {
      return CONST_USER_INFO.SEX_OPTION;
    },
    sex_text() {
      return (value) => {
        if (!this.sex_option[value]) {
          return '';
        }
        return this.sex_option[value];
      };
    },
    sei: {
      get() {
        return this.$store.state.userInfo.inputData.sei;
      },
      set(value) {
        this.$store.commit('userInfo/updateSei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    mei: {
      get() {
        return this.$store.state.userInfo.inputData.mei;
      },
      set(value) {
        this.$store.commit('userInfo/updateMei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    kana_sei: {
      get() {
        return this.$store.state.userInfo.inputData.kana_sei;
      },
      set(value) {
        this.$store.commit('userInfo/updateKanaSei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    kana_mei: {
      get() {
        return this.$store.state.userInfo.inputData.kana_mei;
      },
      set(value) {
        this.$store.commit('userInfo/updateKanaMei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    birth: {
      get() {
        return this.$store.state.userInfo.inputData.birth;
      },
      set(value) {
        this.$store.commit('userInfo/updateBirth', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institution: {
      get() {
        return this.$store.state.userInfo.inputData.institution;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitution', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    errors() {
      return this.$store.state.userInfo.errors;
    },
    isChange() {
      return this.$store.state.userInfo.isChange;
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('userInfo/resetErrorMsg');
    this.selectedTab = this.$route.name;
    const res = await api.send('/api/user/myInfo')
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (res !== false) {
      this.$store.commit('userInfo/setDbData', res.data.userInfo);
    }
    this.$store.commit('userInfo/changeCheck');
    this.$store.dispatch('page/offLoading');
  },
};
</script>


<style scoped>
.title{
  color: #333333;
  font-weight: 300;
  font-size: 24px;
  margin-top: 20px;
  border-bottom: solid 2px #A0A9D0;
  padding-left: 10px;
  padding-bottom: 7px;
}
.inputBox{
  /* text-align: center; */
  margin-top: 35px;
}
button.btn{
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}
button.save{
  background-color: #122889!important;
  color: #FFFFFF!important;
}
.btnWrap{
  text-align: center;
  margin-top: 45px;
}
.institutionNameWrap{
  width: 975px;
}
.institutionNameWrap .labelWrap{
  padding: 5px;
  max-width: 180px;
  width: 180px;
  margin-right: 55px;
}
.institutionNameWrap .labelWrap .label{
  line-height: 40px;
  font-weight: bold;
}
.institutionNameWrap .itemWrap{
  display: flex;
  margin-top:15px;
  margin-bottom:15px;
}
.institutionNameWrap .itemWrap input[type="text"]{
  width: 400px;
  height: 50px;
}
.institutionNameWrap .itemWrap div.itemName{
  width: 100%;
  max-width: 300px;
  text-align: left;
  line-height: 50px;
  margin-right: 10px;
}
.institutionNameWrap .error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 395px;
  margin-top: -12px;
  text-align: left;
  margin-left: 312px;
}
.institutionNameText .labelWrap{
  text-align: left;
  width: 180px;
  max-width: 180px;
  font-weight: bold;
  white-space: pre-line;
  margin-right: 30px;
}
.institutionNameText{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px auto;
  word-break: break-all;
}
.institutionNameText .textWrap{
  text-align: left;
}
.institutionNameText .textWrap div{
  padding-left: 5px;
}
.institutionNameText label{
  margin-bottom: 0;
}
</style>
